<template>
  <div class="container-fluid health" id="healthReource">
    <h2 class="header"> <a href="https://health.afcfta.blog/"  target="_blank">Health</a></h2>
    <hr />

    <div class="row justify-content-center">
      <div class="col-6 col-md-5 col-lg-4 justify-content-center">
        <img src="../../assets/new/africacdclaunch.png" class="img-fluid" alt="">
        <h2 class="text-center association" >In Association with the Africa CDC</h2>
      </div>
    </div>

    <div class="row"  v-if="currentUser.individual && currentUser.individual.is_blog_editor | currentUser.individual.is_superadmin">
      <div class="col-12 col-md-12 col-lg-12 col-xl-12">
        <button 
          type="button" class=" resource"
          data-toggle="modal" 
          data-target="#exampleModalCenter"
          @click="reset">Add New Health Resource</button>
      </div>
    </div>

    <div class="row pt-4 ">

      <div class="col-md-6 col-lg-4 col-xl-4  card_container mb-4" v-for="(resource,key) in healthResources" :key="key">

          <a target="_blank" :href="resource.sourceUrl">
            <div class="news">
            <img
              :src="`${appUrl()}${resource.captionImage}`"
              class="img-responsive"
              alt=""
            />
            <h5 class="pt-4 card_text">{{resource.title}}</h5>
          </div>
          
            <div class="row justify-content-center pt-1  publish">
              <div class="col-7 col-md-7 col-lg-7">
                <small>Published by {{resource.publishedBy}}</small>
              </div>
              <div class="col-5 col-md-5 col-lg-5 text-right">
                <a target="_blank" class="download_btn" :href="resource.sourceUrl">Read more</a>
              </div>
            </div>
          </a>
          <div class="row" v-if="currentUser.individual && currentUser.individual.is_blog_editor | currentUser.individual.is_superadmin">
            <div class="col-l">
              <button 
                data-toggle="modal" data-target="#update" 
                @click="getResource(resource, key)"
                class="btn-outline-primary m-3">Edit
                </button>
              <button  class="m-3 btn-outline-danger btn-sm"
               data-toggle="modal" data-target="#deleteHealthResource"  @click="getResourceID(resource.id, key)">Delete
               </button>
            </div>
          </div>
          
      </div>

    </div>

  
    <!-- Delete Current Event -->
    <div
      class="modal fade"
      id="deleteHealthResource"
      tabindex="-1"
      role="dialog"
      aria-labelledby="currentEventLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="currentEventLabel">
              Delete Resource material
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div  class="modal-bod">
            <div v-if="!success" class="alert alert-warning m-2 p-2">
              Are you sure you want to do this ?
            </div>
            <div v-if="success" class="alert alert-success p-2 m-2">
              {{success}}
            </div>
            <div v-if="error" class="alert alert-danger m-2 p-2">
              {{success}}
            </div>
          </div>
          <div class="modal-footer" v-if="!success">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-danger"
              @click="deleteHealthResource"
            > <div v-if="loading" class="spinner-border spinner-border-sm text-light" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
              Confirm
            </button>
          </div>
          <div class="modal-footer" v-if="success">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- MODAL APP HEALTH CONTENT-->
    <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenter" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalCenterTitle">Add New Health Resource</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="addHealthResource">
              <div class="form-group">
                <label for="title">Title</label>
                <input type="text" class="form-control" id="title" required v-model="formData.title">
              </div>
              <div class="form-group">
                <label for="author">Author (Optional)</label>
                <input type="text" class="form-control" id="author" v-model="formData.author">
              </div>
              <div class="form-group">
                <label for="publishedBy">Published By</label>
                <input type="text" class="form-control" id="publishedBy" required v-model="formData.publishedBy">
              </div>
              <form>
                <div class="form-group">
                  <label for="exampleFormControlFile1">Caption Image</label>
                  <input type="file" class="form-control-file" id="exampleFormControlFile1" required @change="handleCaptionImage">
                  <div class="alert alert-danger" v-if="captionError"> 
                    <p>{{captionError}}</p>
                  </div>
                </div>
              </form>
              <div class="form-group">
                <label for="createdOn">Created On (Optional)</label>
                <input type="date" class="form-control" id="createdOn" v-model="formData.createdOn">
              </div>
               <div class="form-group">
                <label for="sourceUrl">Source URL </label>
                <input type="url" class="form-control" id="sourceUrl" required v-model="formData.sourceUrl">
              </div>
                  <p class="alert alert-success py-2" v-if="success">{{success}}</p>
                  <p class="alert alert-danger" v-if="error">{{error}}</p>
                <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="submit" class="btn btn-success">
                  <div v-if="loading" class="spinner-border spinner-border-sm text-light" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                  Add Health Resource</button>
              </div>
            </form>
          </div>
         
        </div>
      </div>
    </div>
    <!-- END OF MODAL CONTENT -->
    <!--Edit HEALTH CONTENT -->
    <div class="modal fade" id="update" tabindex="-1" role="dialog" aria-labelledby="update" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalCenterTitle">Edit Health Resource</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="editHealthResource">
              <div class="form-group">
                <label for="title">Title</label>
                <input type="text" class="form-control" id="title" required v-model="formData.title">
              </div>
              <div class="form-group">
                <label for="author">Author (optional)</label>
                <input type="text" class="form-control" id="author" v-model="formData.author">
              </div>
              <div class="form-group">
                <label for="publishedBy">Published By</label>
                <input type="text" class="form-control" id="publishedBy" required v-model="formData.publishedBy">
              </div>
              <div class="form-group">
                      <p>Caption Image</p>
                      <div class="base-image-input" @click="chooseImage">
                        <img :src="imageUrl" class="preview_img" id="preview_image" alt="">
                        <input class="file-input" ref="fileInput" type="file" @change="handleCaptionImage">
                    </div>
                    <div class="alert alert-danger" v-if="captionError">
                      <p>{{ captionError }}</p>
                    </div>

                  </div>
              <div class="form-group">
                <label for="createdOn">Created On (optional)</label>
                <input type="date" class="form-control" id="createdOn" v-model="formData.createdOn">
              </div>
               <div class="form-group">
                <label for="sourceUrl">Source URL </label>
                <input type="url" class="form-control" id="sourceUrl" required v-model="formData.sourceUrl">
              </div>
                <p class="alert alert-success py-2" v-if="success">{{success}}</p>
                <p class="alert alert-danger" v-if="error">{{error}}</p>
              <div class="modal-footer">

                  <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                  <button type="submit" class="btn btn-success" >
                    Edit health resources
                    <div v-if="loading" class="spinner-border spinner-border-sm text-light" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                    </button>
              </div>
            </form>
          </div>
         
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import url from '../../helpers/url'
import Storage from '../../helpers/storage'
import { mapGetters } from 'vuex'
import axios from 'axios'
import moment from 'moment'

export default {
  name:'health',
  data() {
    return {
      token:'',
      fileInput:'',
      formData: {
        title:'',
        author:'',
        createdOn:[],
        publishedBy:'',
        sourceUrl:'',
        captionImage:''
      },
      captionError:'',
      success: '',
      error:'',
      loading:false,
      resource: {

      },
      healthResources:[],
      resourceError:null,
      id:'',
      key:'',
      imageUrl:'',
    }
  },
  computed:{
    ...mapGetters(['currentUser'])
  },

  
  methods: {
    chooseImage(){
      this.$refs.fileInput.click()
    },
    reset() {
      this.formData = {

      }
      this.error = null
      this.loading = false
      this.success = null
    },
    moment() {
      return moment()
    },
    appUrl() {
      return url()
    },
    getResourceID(id, key) {
      this.success = null
      this.error = null
      this.loading = false

      this.id = id
      this.key = key
    },
    
    async editHealthResource(){
      try {
        
        this.success = null
        this.error = null
        this.loading = true

       let newFormData = new FormData();

        if (!this.formData.captionImage) {

            this.loading = false
            this.captionError = "No file uploaded"
            return

        } else {

          this.captionError = null

        }
      
        if(this.formData.createdOn){
           let formartDate = new Date(this.formData.createdOn).toISOString();
           newFormData.append("createdOn", formartDate);
        } else {
          newFormData.append("createdOn", "");
        }

        if(this.formData.author) {
          newFormData.append("author", this.formData.author);
        } else {
          newFormData.append("author", "");
        }

        newFormData.append("title", this.formData.title);
        
        newFormData.append("publishedBy", this.formData.publishedBy);
       
        newFormData.append("sourceUrl", this.formData.sourceUrl);
        if(this.formData.captionImage) {
          newFormData.append('captionImage',this.formData.captionImage)
        } else {
          newFormData.append('captionImage','')
        }

        axios.defaults.headers.common['Authorization'] = this.token
        const response = await axios.patch(`${url()}/api/blog/update-health/${this.id}/`)

        this.success = "Health resource edited"
        this.healthResources[this.key] = response.data

      } catch (err) {
        console.log(err)
        this.loading = false
        if(err?.response?.data == undefined) {
          this.error = "Network Error. Try again."
        }
      }
    },

    async deleteHealthResource(){
      try {
        this.success = null
        this.error = null
        this.loading = true
        const response = await axios.delete(`${url()}/api/blog/delete-health/${this.id}`,
        {
          headers: {
            "Authorization": this.token
          }
        })
        this.success = "Health resource deleted"
        this.healthResources.splice(this.key,1)
      } catch (err) {
        this.loading = false
        if(err?.response?.data == undefined) {
          this.error = "Network Error. Try again."
        }
      }
    },

    async fetchHealthResources() {
      try {
        const response = await axios.get(`${url()}/api/blog/fetch-health`)
        this.healthResources = response.data.Health
      } catch (err) {
        if(err?.response?.data == undefined) {
          this.resourceError = "Network Error. Try again."
        }
      }
    },

    getResource(data, key) {
      this.success = null
      this.error = null
      this.loading = false

      this.id = data.id
      this.key = key
      this.formData.title = data.title
      this.formData.publishedBy = data.publishedBy
      this.formData.author = data.author === "undefined" ? " ": data.author
      this.formData.sourceUrl = data.sourceUrl
      this.formData.createdOn = data.createdOn ? moment(data.createdOn).format('').split("T")[0] : ''
      this.formData.captionImage = data.captionImage
      this.imageUrl = `${url()}${data.captionImage}`
      // 
    },
    handleCaptionImage(e) {

      let files = e.target.files;

      this.formData.captionImage = files[0]

      if(!this.formData.captionImage) {

       this.captionError = "Caption image is required."

      }
      // change update image 
      // this file reader wil read and remove the already existing image from the ui
      if (files && files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
           document.getElementById('preview_image').src  = e.target.result;
        }
        reader.readAsDataURL(files[0]);
    }

    },

    async addHealthResource() {
    
      try {
        this.loading = true
        this.error = null
        this.success = null

        // validating caption image
        let newFormData = new FormData()
        if (!this.formData.captionImage) {
            this.loading = false
            this.captionError = "No file uploaded"
            return
        } else {
          this.captionError = null
        }
        if(this.formData.createdOn){
           formartDate = new Date(this.formData.createdOn).toISOString();
           newFormData.append("createdOn", formartDate);
        } else {
          newFormData.append("createdOn", '');
        }
        if(this.formData.author) {
          newFormData.append("author", this.formData.author);
        } else {
          newFormData.append("author", '');
        }
        if(this.formData.captionImage) {
          newFormData.append('captionImage',this.formData.captionImage)
        } else {
          newFormData.append('captionImage','')
        }
        newFormData.append('title',this.formData.title)
        newFormData.append('publishedBy', this.formData.publishedBy)
        newFormData.append('sourceUrl', this.formData.sourceUrl)
        
        //  headers: {
        //               'Authorization':`${Vue.prototype.$session.get('entity')}`
        //           }
        const response = await axios.post(`${url()}/api/blog/add-health`,
        newFormData, 
        {
          headers: {
            "Authorization": this.token
          }
        }
        )
        
        this.healthResources.push(response.data.Health)
        this.loading = false
        this.success = "Health resource uploaded successfully."
        this.formData = {

        }
        

      } catch (err) {
        console.log(err)
        this.loading = false
        if(err?.response?.data == undefined) {
          this.error = "Network error, check your connection and try again"
        }
      }
    
      
    }
  },
  created(){
    this.token = Storage.get('token')
    this.fetchHealthResources()
  }
};
</script>

<style  scoped>
.resource{
  background-color:rgb(235, 41, 41);
  float:right;
  border:none;
  outline:none;
  color:#fff;
  font-size: 1.2em;
  padding:.6em;
  transition: all 0.5s;
}
.resource:hover{
  outline: none;
  border:none;
  opacity: .7;
}
.association{
  font-weight: bold;
  padding-top:.1em;
  padding-bottom: 2em;
  font-size: 1.4em;
}
.health{
  padding: 7em 12%;
}
hr {
  border: 1px solid #000;
}
.img-responsive {
  height: 250px;
  width: 100%;
  object-fit: cover;
}
a {
  text-decoration: none;
  color:#000;
}
.more {
  color: #8c8c8c;
}
.view_more{
  text-decoration: none;
  background-color: transparent;
  color: #8c8c8c;
}
.iconify {
  border: 1px solid #8c8c8c;
}
.download_btn{
  border: 1px solid rgba(0,0,0,.7);
  outline:none;
  transition: all 0.5s;
  padding:7px 10px;
  background-color: transparent;
  text-decoration: none;
  color:#000;
}
.view_more:focus, .view_more:active, .view_more:hover, .download_btn:hover{
  outline:none;
  background-color: rgb(235, 41, 41);
  color:#fff;
  border:1px solid  rgb(235, 41, 41);
}
@media screen and (max-width: 1100px) {
  .health{
  padding: 4em  5% 0 5%;
}
}
@media screen and (max-width: 768px) {
  .association{
    font-size: 1em;
  }
  .news{
    margin-bottom: 5px;
    /* padding: 10px; */
  }
  div.card_container:not(:last-of-type){
    margin-bottom:3em;
  }
}
@media screen and (min-width:768px){
  .card_text{
    height: 160px;
  }
}
</style> 