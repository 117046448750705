<template>
  <div class="container-fluid enterpreneur" id="entretreneurship">
    <h2 class="header">Entrepreneurship</h2>
    <hr />

    <div class="row" v-if="currentUser.individual && currentUser.individual.is_blog_editor | currentUser.individual.is_superadmin">
      <div
        class="col-12 col-md-12 col-lg-12 col-xl-12"
      >
        <button
          type="button"
          class="resource"
          data-toggle="modal"
          data-target="#entreprenuerModalCenter"
          @click="reset"
        >
          Add New Enterpreneur Material
        </button>
      </div>
    </div>

    <div class="row pt-4">
     
        <div
          class="col-md-6 col-lg-4 col-xl-4card_container mb-4"
          v-for="(entre, key) in Entreprenures"
          :key="key"
        >
          <a target="_blank" href="#">
            <div class="news">
              <img
                :src="`${appUrl()}${entre.captionImage}`"
                class="img-responsive"
                alt=""
              />
              <h5 class="pt-4 card_text">
                {{ entre.title }}
              </h5>
            </div>
            <div class="row justify-content-between pt-1 pb-3">
              <div class="col-7 col-md-7 col-lg-7 ">
                <small>Published by {{ entre.publishedBy }}</small>
              </div>
             <div class="col-5 col-md-5 col-lg-5 text-right">
                <a target="_blank" class="download_btn" :href="entre.sourceUrl">Read more</a>
              </div>
            </div>
          </a>
          <div class="row" v-if="currentUser.individual && currentUser.individual.is_blog_editor | currentUser.individual.is_superadmin">
            <div class="col-l">
                <button
                  class=" btn-outline-primary btn-sm  m-3"
                  data-target="#openeditmodal"
                  data-toggle="modal"
                  @click="openEditModal(entre, key)"
                >
                  Edit
                </button>
                <button
                  data-toggle="modal"
                  data-target="#currentEvent"
                  class="btn-outline-danger btn-sm m-3"
                  @click="getDeletedDetails(entre.id,key)"
                  >
                  Delete
                </button>
              </div>
          </div>
        </div>

      <!-- Add new record -->
      <div
        class="modal fade"
        id="entreprenuerModalCenter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="entreprenuerModalCenter"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="entreprenuerModalCenterTitle">
                Add New Entrepreneur Material
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form @submit.prevent="addEntreprenuer">
                <div class="form-group">
                  <label for="title">Title</label>
                  <input
                    type="text"
                    class="form-control"
                    id="title"
                    required
                    v-model="formData.title"
                  />
                </div>
                <div class="form-group">
                  <label for="author">Author (Optional)</label>
                  <input
                    type="text"
                    class="form-control"
                    id="author"
                    v-model="formData.author"
                  />
                </div>
                <div class="form-group">
                  <label for="publishedBy">Published By</label>
                  <input
                    type="text"
                    class="form-control"
                    id="publishedBy"
                    required
                    v-model="formData.publishedBy"
                  />
                </div>
                <form>
                  <div class="form-group">
                    <label for="exampleFormControlFile1">Caption Image</label>
                    <input
                      type="file"
                      class="form-control-file"
                      id="exampleFormControlFile1"
                      @change="handleCaptionImage"
                      required
                    />
                    <div class="alert alert-danger" v-if="captionError">
                      <p>{{ captionError }}</p>
                    </div>
                  </div>
                </form>
                <div class="form-group">
                  <label for="createdOn">Created On (Optional)</label>
                  <input
                    type="date"
                    class="form-control"
                    id="createdOn"
                    v-model="formData.createdOn"
                  />
                </div>
                <div class="form-group">
                  <label for="sourceUrl">Source URL </label>
                  <input
                    type="url"
                    class="form-control"
                    id="sourceUrl"
                    required
                    v-model="formData.sourceUrl"
                  />
                </div>
                <p class="alert alert-success py-2" v-if="success">
                  {{ success }}
                </p>
                <p class="alert alert-danger" v-if="error">{{ error }}</p>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="submit" class="btn btn-success">
                    <div
                      v-if="loading"
                      class="spinner-border spinner-border-sm text-light"
                      role="status"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
        <!-- Delete Current Entrepreneurship  -->
          <div
            class="modal fade"
            id="currentEvent"
            tabindex="-1"
            role="dialog"
            aria-labelledby="currentEventLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="currentEventLabel">
                    Delete entrepreneurship material
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                   <div class="alert alert-warning" v-if="!success">
                     <p>Are you sure you want to do this ?</p>
                   </div>
                   <div class="alert alert-success" v-if="success">
                     <p>{{success}}</p>
                   </div>
                   <div class="alert alert-danger" v-if="error">
                     <p>{{error}}</p>
                   </div>
                </div>
                <div class="modal-footer" v-if="!success">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    class="btn btn-danger"
                    @click="deleteEntrepreneuship"
                  > <div
                      v-if="loading"
                      class="spinner-border spinner-border-sm text-light"
                      role="status"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                    Confirm
                  </button>
                </div>
                <div class="modal-footer" v-if="success">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal">
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
      <!-- Update  -->
      <div
        class="modal fade"
        id="openeditmodal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="update"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalCenterTitle">
                Edit Entrepreneur Material
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form @submit.prevent="editEntrepreneurshipResource">
                <div class="form-group">
                  <label for="title">Title</label>
                  <input
                    type="text"
                    class="form-control"
                    id="title"
                    required
                    v-model="formData.title"
                  />
                </div>
                <div class="form-group">
                  <label for="author">Author (optional)</label>
                  <input
                    type="text"
                    class="form-control"
                    id="author"
                    v-model="formData.author"
                  />
                </div>
                <div class="form-group">
                  <label for="publishedBy">Published By</label>
                  <input
                    type="text"
                    class="form-control"
                    id="publishedBy"
                    required
                    v-model="formData.publishedBy"
                  />
                </div>
                
                  <div class="form-group">
                      <p>Caption Image</p>
                      <div class="base-image-input" @click="chooseImage">
                        <img :src="imageUrl" class="preview_img" id="preview_image" alt="">
                        <input class="file-input" ref="fileInput" type="file" @change="handleCaptionImage">
                    </div>
                    <div class="alert alert-danger" v-if="captionError">
                      <p>{{ captionError }}</p>
                    </div>

                  </div>
                
                <div class="form-group">
                  <label for="createdOn">Created On (optional)</label>
                  <input
                    type="date"
                    class="form-control"
                    id="createdOn"
                    v-model="formData.createdOn"
                  />
                </div>
                <div class="form-group">
                  <label for="sourceUrl">Source URL </label>
                  <input
                    type="url"
                    class="form-control"
                    id="sourceUrl"
                    required
                    v-model="formData.sourceUrl"
                  />
                </div>
                <p class="alert alert-success py-2" v-if="success">
                  {{ success }}
                </p>
                <p class="alert alert-danger" v-if="error">{{ error }}</p>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    class="btn btn-success"
                  >
                    Edit Enterprenuership
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import url from "../../helpers/url";
import { mapGetters } from "vuex";
import moment from 'moment'
export default {
  data() {
    return {
      formData: {
        title: "",
        author: "",
        createdOn: "",
        publishedBy: "",
        sourceUrl: "",
        captionImage: "",
      },
      captionError: "",
      success: "",
      error: "",
      loading: false,
      Entreprenures: [],
      Enterprenuership: {},
      id:'',
      key:'',
      imageUrl:'',
    };
  },
  computed: {
    ...mapGetters(["currentUser",'token']),
  },
  methods: {
    chooseImage(){
      this.$refs.fileInput.click()
    },
    openEditModal(data, key){

      this.success = null
      this.error = null
      this.loading = false

      this.id = data.id
      this.key = key
      this.formData.title = data.title
      this.formData.publishedBy = data.publishedBy
      this.formData.author = data.author === "undefined" ? " ": data.author // change author logic
      this.formData.sourceUrl = data.sourceUrl
      this.formData.createdOn = data.createdOn ? moment(data.createdOn).format('').split("T")[0] : ''

      this.formData.captionImage = data.captionImage

      this.imageUrl = `${url()}${data.captionImage}`

    },
    reset() {
      this.loading = false
      this.error = null
      this.success = null
    },
    getDeletedDetails(id, key){
      this.success = null
      this.error = null
      this.loading = false

      this.id = id
      this.key = key
    },
    appUrl() {
      return url();
    },

    getEntreprenure() {
      axios.get(`${url()}/api/blog/fetch-entrepreneurship`).then((response) => {
        this.Entreprenures = response.data.Enterprenuership
      });
    },

    handleCaptionImage(e) {

      let files = e.target.files;

      this.formData.captionImage = files[0]

      if(!this.formData.captionImage) {

       this.captionError = "Caption image is required."

      }
      // change update image 
      // this file reader wil read and remove the already existing image from the ui
      if (files && files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
           document.getElementById('preview_image').src  = e.target.result;
        }
        reader.readAsDataURL(files[0]);
    }

    },

    getEnterprenuership(id) {
      axios
        .get(`${url()}/api/blog/fetch-single-entrepreneurship/${id}/`)
        .then((response) => {
          this.Enterprenuership = response.data.Enterprenuership;
        });
    },
    async deleteEntrepreneuship(){
      try {
        this.success = null
        this.error = null
        this.loading = true
        const response = await axios.delete(`${url()}/api/blog/delete-entrepreneurship/${this.id}/`,
        {
          headers: {
            "Authorization": this.token
          }
        })

        this.success = "Entrepreneurship resource deleted"
        this.Entreprenures.splice(this.key,1)
      } catch (err) {
        this.loading = false
        if(err?.response?.data == undefined) {
          this.error = "Network Error. Try again."
        }
      }
    },
     async editEntrepreneurshipResource(){
      try {
        this.success = null
        this.error = null
        this.loading = true

        // change file validation
        let newFormData = new FormData();

        if (!this.formData.captionImage) {
            this.loading = false
            this.captionError = "No file uploaded"
            return
        } else {
          this.captionError = null
        }

        let formartDate = ''

        if(this.formData.createdOn){
           formartDate = new Date(this.formData.createdOn).toISOString();
           newFormData.append("createdOn", formartDate);
        } else {
          newFormData.append("createdOn", "");
        }

        if(this.formData.author) {
          newFormData.append("author", this.formData.author);
        } else {
          newFormData.append("author", "");
        }

        newFormData.append("title", this.formData.title);
        
        newFormData.append("publishedBy", this.formData.publishedBy);
        newFormData.append("captionImage", this.formData.captionImage);
        newFormData.append("sourceUrl", this.formData.sourceUrl);

        console.log('caption image', this.formData.captionImage)
        console.log('author', this.formData.author)

        axios.defaults.headers.common['Authorization'] = this.token

        const response = await axios.patch(`${url()}/api/blog/update-entrepreneurship/${this.id}/`)

        this.success = "Entrepreneurship resource edited"
        this.healthResources[this.key] = response.data

      } catch (err) {
        console.log(err)
        this.loading = false
        if(err?.response?.data == undefined) {
          this.error = "Network Error. Try again."
        }
      }
    },
    async addEntreprenuer() {
      try {
        this.loading = true;
        this.success = null
        this.error = null
        
        // change file validation
        let newFormData = new FormData();

        if (!this.formData.captionImage) {
            this.loading = false
            this.captionError = "No file uploaded"
            return
        } else {
          this.captionError = null
        }

        if(this.formData.createdOn){
           let formartDate = new Date(this.formData.createdOn).toISOString();
           newFormData.append("createdOn", formartDate);
        } else {
          newFormData.append("createdOn", "");
        }

        if(this.formData.author) {
          newFormData.append("author", this.formData.author);
        } else {
          newFormData.append("author", "");
        }

        newFormData.append("title", this.formData.title);
        
        newFormData.append("publishedBy", this.formData.publishedBy);
        newFormData.append("captionImage", this.formData.captionImage);
        newFormData.append("sourceUrl", this.formData.sourceUrl);

        const response = await axios.post(
          `${url()}/api/blog/add-entrepreneurship`,
          newFormData,
          {
            headers: {
              Authorization: this.token
            },
          }
        );
        this.Entreprenures.push(response.data.enterprenuership);
        this.loading = false;
        this.success = "Entrepreneurship resource uploaded successfully.";
        this.formData = {};

      } catch (err) {
        console.log('error', err)
        this.loading = false;
        if (err?.response?.data == undefined) {
          this.error = "Network error, check your connection and try again";
        }
      }

    },

    

  },
  mounted() {
    this.getEntreprenure();
  },
};
</script>

<style  scoped>

  /* WHAT IS GOING ON NOW  */
.resource {
  background-color: rgb(235, 41, 41);
  float: right;
  border: none;
  outline: none;
  color: #fff;
  font-size: 1.2em;
  padding: 0.6em;
  transition: all 0.5s;
}
.resource:hover {
  outline: none;
  border: none;
  opacity: 0.7;
}
.enterpreneur {
  padding-left: 12%;
  padding-bottom: 7em;
  padding-right: 12%;
}
.img-responsive {
  height: 250px;
  width: 100%;
  object-fit: cover;
}
a {
  text-decoration: none;
  color: #000;
}
hr {
  border: 1px solid #000;
}

.more {
  color: #8c8c8c;
}
.view_more {
  text-decoration: none;
  background-color: transparent;
  color: #8c8c8c;
}
.iconify {
  border: 1px solid #8c8c8c;
}
.download_btn {
  border: 1px solid rgba(0, 0, 0, 0.7);
  outline: none;
  transition: all 0.5s;
  padding: 7px 10px;
  background-color: transparent;
  text-decoration: none;
  color: #000;
}
.view_more:focus,
.view_more:active,
.view_more:hover,
.download_btn:hover {
  outline: none;
  background-color: rgb(235, 41, 41);
  color: #fff;
  border: 1px solid rgb(235, 41, 41);
}
@media screen and (max-width: 1100px) {
  .enterpreneur {
    padding: 4em 5%;
  }
}
@media screen and (max-width: 768px) {
  .news {
    margin-bottom: 5px;
  }
  div.card_container:not(:last-of-type) {
    margin-bottom: 3em;
  }
}
@media screen and (min-width:768px){
  .card_text{
    height: 130px;
  }
}
</style>