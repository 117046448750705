<template>
  <section class="get_in_touch " id="contactUs">
    <div class="row justify-content-center">
      <div class="col-md-12">
        <h1 class="text-white py-2"><b>Tell us what's on your mind</b></h1>
        <form action="" @submit="contactUs">
          <div class="contact-form justify-content-between row">

            <div class="form-field col-md-6 top_form">
              <input required type="text" class="input-text" id="name" name="name" placeholder="Name" v-model="name" />
              <label for="name" class="label" placeholder="name"></label>
            </div>
            <div class="form-field col-md-6 top_form_1">
              <input type="email" class="input-text" 
                placeholder="Email" id="email" name="email" required  
                v-model="email" />
              <label for="email" class="label"></label>
            </div>
            <div class="form-field col-md-12">
              <input
                placeholder="Organization"
                type="text"
                class="input-text"
                id="organization"
                name="organization"
                v-model="organization"
              />
              <label for="organization" class="label"></label>
            </div>
            <div class="form-field col-md-12">
              <input
                placeholder="Message"
                type="text"
                class="input-text"
                id="message"
                name="message"
                v-model="message"
                required 
              />
              <label for="message" class="label"></label>
            </div>

            <div class="form-field col-md-12" >
              <button style="float:right" type="submit"  id="submit">Submit</button>
            </div>
          </div>
        </form>
    </div>
    </div>
  </section>
</template>

<script>
// import toasterMixin from '../mixin/toasterMixin' // no unused var
import axios from 'axios'
import url from '../../helpers/url'

export default {
  name:'contact-us',
  data(){
    return {
      email:null,
      message:null,
      organization:null,
      name:null
    }

  },
  methods: {
   contactUs() {
    const data = {
        name:this.name,
        organization:this.organization,
        message:this.message,
        email:this.email
    }
    axios.post(`${url()}/api/contact-form`, data)
      .then(response => {
          if(response) {
              this.toast({
                  message:'Your message has been sent successfully, Our team will get back to you soon.',
                  type:'success'
              })
          }
          this.email = null
          this.organization = null
          this.name = null
          this.message = null
      }).catch(err => {
          if(err?.response?.data == undefined) {
              this.toast({
                  message:'Network error. Check your network connection and try again.'
              })
          }
      })
    },
  }
}
</script>

<style scoped>
.top_form{
  padding-right: 2em;
}
.top_form_1{
  padding-left: 2em;
}
.get_in_touch {
  background-color:#0d0d0d;
  padding: 9em 10%;
}
.contact-form .form-field {
  position: relative;
  margin: 10px 0;
}
.form-field{
  margin-top:2em !important;
}
.contact-form .input-text {
  display: block;
  width: 100%;
  background: #0d0d0d;;
  height: 36px;
  border-width: 0 0 2px 0;
  border-color: #555454;
  font-size: 18px;
  color: #fff;
  line-height: 26px;
  font-weight: 400;
}

.contact-form .input-text:focus {
  outline: none;
}

.contact-form .label {
  position: absolute;
  left: 20px;
  bottom: 5px;
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  color: #fff;
  cursor: pointer;
}
::placeholder{
  color:#555454;
}
#submit{
  background-color:#ba2328;
  outline:none;
  border:none;
  transition: all 0.4s;
  padding:.6em 3em;
  color:#ffff;
  margin-top:1em;
  font-size: 1.2em;
}
#submit:hover {
  border:none;
  opacity: 0.6;
  outline:none;
  font-weight: bolder;
}
input{
  padding-bottom: 1em;
  padding-left:1em;
}
 @media screen and (max-width:767px) {
   .get_in_touch {
     padding: 9em 5%;
    }
    .top_form{
      padding-right: 1em!important;
      padding-left: 1em!important;
    }
    .top_form_1{
      padding-left: 1em!important;  
      padding-right: 1em!important;  
    }
}
 @media screen and (max-width:575px) {
   h1{
       text-align: center;
   }
   .form-field{
     text-align: center !important;
   }
   button {
     width: 100%;
   }
   
 }
</style>