<template>
  <div class="container-fluid trending_debates">
    <h2 class="header">Trending Debates</h2>
    <hr />

    <div class="row trending pb-5" v-for="(debate, key) in trendingDebates" :key="key">
      <div class="col-md-7">
        <small class="my-5">Published by {{debate.author}}</small>
        <h3 class="pt-3">{{debate.topic}}</h3>
        <p>
          {{debate.body &&  debate.body.slice(0,400)}} ... <router-link :to="`debates/${debate.id}`">read more</router-link>
        </p>

        <div class="row pt-3 pb-5 ">
          <div class="col-7 col-md-7 col-lg-7">
            <button @click="addLeadOpinion" class="download_btn">
              Add Lead Opinion
            </button>
          </div>
          <div class="col-5 col-md-5 col-lg-5">
            <small class="pr-4 date">{{debate.createdOn}}</small>
          </div>
        </div>

      </div>

      <div class="col-md-5">
        <img
          :src="`${url()}${debate.pic}`"
          class="img-responsive"
          alt=""
        />
      </div>
    </div>

    <!-- <div class="row">
      <div class="col-md-12 text-center">
        <button class=" view_more">View more</button>
      </div>;
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import url from '../../helpers/url'
export default {
  data() {
    return {
      trendingDebates: []
    }
  },
  computed: {
    ...mapGetters(['token'])
  },
  methods: {
    url(){
      return url()
    },
     addLeadOpinion(){
      if(!token) {
        return  this.$router.replace(`/redirect?service=login`)
      }  
      this.$router.replace(`debates/${trendingDebates.id}/add_lead_opinion`)           
    },
    fetchDebates() {
      this.$store.dispatch('fetchApprovedDebateArticle')
          .then( response => {
             const data = response.data
             this.trendingDebates = data
             console.log('response data', data)      
          })
          .catch(err => {
              
              if(err?.response?.data == undefined) {
              this.toast({
                  message:'Network error. Check network connection and try again.',
                  type:'error'
              })
          }
          })
    }

  },
  created() {
    this.fetchDebates()
  }
};
</script>

<style  scoped>
.img-responsive {
  height: 300px;
  width: 100%;
  object-fit: cover;
}
.trending_debates{
   padding-bottom: 7em;
   padding-right: 12%;
   padding-left: 12%;
}
hr {
  border: 1px solid #000;
}
.view_more{
  border: 1px solid rgba(0,0,0,.7);
  outline:none;
  transition: all 0.5s;
  padding:10px 50px;
  background-color: transparent;
}
.download_btn{
  border: 1px solid rgba(0,0,0,.7);
  outline:none;
  transition: all 0.5s;
  padding:10px 20px;
  background-color: transparent;
  text-decoration: none;
  color:#000;
}
.view_more:focus, .view_more:active, .view_more:hover, .download_btn:hover{
  outline:none;
  background-color: rgb(235, 41, 41);
  color:#fff;
  border:1px solid  rgb(235, 41, 41);
}
.more {
  color: #8c8c8c;
}

h1 {
  color: #000;
}
h3{
  font-weight: bold;
}
.date{
  font-style: italic;
}
.iconify {
  border: 1px solid #8c8c8c;
}
@media screen and (max-width:1100px) {
  .trending_debates{
  padding-bottom: 4em;
  padding-right: 5%;
  padding-left: 5%;
}
}
@media screen and (max-width: 768px) {
  .trending {
    margin-bottom: 15px;
    padding: 10px;
  }
}
</style>